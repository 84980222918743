import React, { useState, useEffect, useCallback,useRef } from 'react';
import { OpenVidu } from 'openvidu-browser';
import axios from 'axios';
import { useUnityContext } from "react-unity-webgl";
import {UNITY_URLS} from '../../utils/config';
import './App.css';
import UserVideoComponent from './UserVideoComponent';
import ShareScreenBox from './ShareScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faUsers, faSignOutAlt, faPhoneFlip, faPhoneAlt, faPhoneSlash, faPhoneSquare } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const APPLICATION_SERVER_URL = 'https://demos.openvidu.io/';

const OpenViduApp = (props) => {
  const {addEventListener, removeEventListener} = useUnityContext(UNITY_URLS);
  const [mySessionId, setMySessionId] = useState('HolofairSession2');
  const [myUserName, setMyUserName] = useState('');
  const [session, setSession] = useState(undefined);
  const [mainStreamManager, setMainStreamManager] = useState(undefined);
  const [publisher, setPublisher] = useState(undefined);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [screenPublisherCt, setScreenPublisher] = useState(null);
  const [st, setSt] = useState(null);
  const [afterShare, setAfterShare] = useState(true)
  const [streamKey, setStreamKey] = useState(Date.now());
  const [time, setTime] = useState(0); // Time in seconds
  const [timerRunning, setTimerRunning] = useState(false);

  const joinSessionCalled = useRef(false);
  const [hasJoined, setHasJoined] = useState(false);

  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timerIntervalId, setTimerIntervalId] = useState(null);

  const [subscribers, setSubscribers] = useState([]);
  const [showParticipantsList,setShowParticipantsList] = useState(false);
  const OV = useRef(null);


  const leaveSession = useCallback(() => {
    console.log('Triggered')
   // localStorage.setItem('ov','');

   if (publisher) {
   // publisher.stream.getMediaStream().getAudioTracks().forEach(track => track.stop());
    console.log(publisher.stream.getMediaStream().getAudioTracks().forEach(track => track.stop()));
    publisher.publishAudio(false);
    session.unpublish(publisher);
}
    if (session) {
      session.disconnect();
    }
    props.triggerLeave(false)
    OV.current = null;
    setSession(undefined);
    joinSessionCalled.current = false;
    setSubscribers([]);
    setMySessionId('');
    setMyUserName('');
    setMainStreamManager(undefined);
    setPublisher(undefined);
    props.update(false)
    
    
  }, [props, publisher, session]);





  function formatTime(timeInSeconds) {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
  
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }


  const formatTimeElapsed = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = seconds.toString().padStart(2, '0');
  
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };





  const handleChangeSessionId = (e) => {
    setMySessionId(e.target.value);
  };

  const handleChangeUserName = (e) => {
    setMyUserName(e.target.value);
  };

  const handleMainVideoStream = (stream) => {
    if (mainStreamManager !== stream) {
      setMainStreamManager(stream);
    }
  };


  const getToken =  useCallback(async () => {
    const sessionId = await createSession(mySessionId);
    return await createToken(sessionId);
  },[mySessionId]);

  const deleteSubscriber = useCallback((streamManager) => {
    const index = subscribers.indexOf(streamManager, 0);
    if (index > -1) {
      const newSubscribers = [...subscribers];
      newSubscribers.splice(index, 1);
      setSubscribers(newSubscribers);
    }
  },[subscribers]);

  const joinSession = useCallback(async () => {
    OV.current = new OpenVidu();
    const newSession = OV.current.initSession();
    // setMySessionId(props.sessionname);
    // setMyUserName(props.username + Math.floor(Math.random() * 100));

    newSession.on('streamCreated', (event) => {
      const subscriber = newSession.subscribe(event.stream, undefined);
      setSubscribers((prevSubscribers) => [...prevSubscribers, subscriber]);
    });

    newSession.on('streamDestroyed', (event) => {
      deleteSubscriber(event.stream.streamManager);
      event.preventDefault(); 
    //  const updatedSubscribers = subscribers.filter(sub => sub.stream.connection.connectionId !== event.stream.connection.connectionId);
    //  setSubscribers(updatedSubscribers);
   // const updatedSubscribers = subscribers.filter(sub => sub.stream.connection.connectionId !== event.stream.connection.connectionId);
    setSubscribers(currentSubscribers => currentSubscribers.filter(sub => sub.stream.connection.connectionId !== event.stream.connection.connectionId));

    });

    newSession.on('exception', (exception) => {
      console.warn(exception);
    });

    setSession(newSession);


    const token = await getToken();
    newSession.connect(token, { clientData: props.username })
      .then(async () => {
        const newPublisher = await OV.current.initPublisherAsync(undefined, {
          audioSource: undefined,
          videoSource: false,
          publishAudio: audioEnabled,
          publishVideo: videoEnabled,
          resolution: '640x480',
          frameRate: 30,
          insertMode: 'APPEND',
          mirror: false,
        });

        newSession.publish(newPublisher);

        setMainStreamManager(newPublisher);
        setPublisher(newPublisher);


        setTimeElapsed(0);
        if (timerIntervalId) clearInterval(timerIntervalId); 
        
        const intervalId = setInterval(() => {
          setTimeElapsed((prevTime) => prevTime + 1); 
        }, 1000); // 1000 milliseconds
        
        setTimerIntervalId(intervalId);





      })
      .catch((error) => {
        console.log('There was an error connecting to the session:', error.code, error.message);
      });
  }, [audioEnabled, deleteSubscriber, getToken, props.sessionname, props.username, timerIntervalId, videoEnabled]);

  const leaveDolbyfunc = useCallback(()=>{
    console.log("Leaving now")
    leaveSession();
    
    

  },[leaveSession])



  useEffect(() => {


    // if(!videoRef.current && publisher) {
    //   videoRef.current.srcObject = publisher.stream.getMediaStream();
    // }

    let interval = null;

  // if (timerRunning) {
  //   interval = setInterval(() => {
  //     setTime((prevTime) => prevTime + 1);
  //   }, 1000);
  // } else if (!timerRunning && time !== 0) {
  //   clearInterval(interval);
  // }
  
 


  console.log(streamKey)
  console.log(publisher)
  console.log(publisher?.stream.audioActive)
  subscribers.map((sub,i)=> {
    return console.log(sub?.stream.audioActive)
  })

  // if(props.username !== '' && props.sessionname !== '' && localStorage.getItem('ov') === ''){
  //   localStorage.setItem('ov','yes');
  //   joinSession()
  //   if(!session){
  //    // joinSession()
  //     console.log(session)
  //     //return;
  //   }else{
  //     console.log(session)
  //   }
    
  // }



  if(props.username !== '' && props.sessionname !== ''){
    localStorage.setItem('ov',props.username);
    setMySessionId(props.sessionname);
    setMyUserName(props.username + Math.floor(Math.random() * 100));
    setHasJoined(true);
  }

 // if(hasJoined === true && localStorage.getItem('ov') === props.username){
    if(hasJoined === true && mySessionId !== '' && joinSessionCalled.current === false){
     joinSession();
     joinSessionCalled.current = true;
    }


     addEventListener("leaveOpenVidu",leaveDolbyfunc);
    const handleBeforeUnload = () => leaveSession();
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
     removeEventListener("leaveOpenVidu",leaveDolbyfunc);
    window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    
  }, [addEventListener, joinSession, leaveDolbyfunc, leaveSession, props.sessionname, props.username, publisher, removeEventListener, session, streamKey, subscribers, time, timerRunning]);
  

 



  const toggleAudio_prev = useCallback(() => {
    setAudioEnabled((prevAudioEnabled) => {
      if (publisher) {
        publisher.publishAudio(!prevAudioEnabled);
      }
      return !prevAudioEnabled;
    });
  }, [publisher]);


  const toggleAudio = useCallback(() => {
    const newAudioEnabled = !audioEnabled;
    setAudioEnabled(newAudioEnabled);
    if (publisher) {
      publisher.publishAudio(newAudioEnabled);
    }
  }, [audioEnabled, publisher]);



  const toggleVideo = () => {
    if (publisher) {
      const videoEnabled = !publisher.stream.videoActive;
      publisher.publishVideo(videoEnabled);
      setVideoEnabled(videoEnabled); 
    }
  };
  

  // Other Methods


  const isScreenShare = (streamManager) => {
    
  };

  

  



  const shareScreen1 = async () => {
    if (!OV.current) {
      console.error('OpenVidu instance is not initialized');
      return;
    }
  
    try {
      // OV.current.isScreenShareSupported()
      const isScreenShareSupported = OV.current.checkSystemRequirements();
      if (!isScreenShareSupported) {
        alert('Your browser does not support screen sharing');
        return;
      }
  
      
      const screenPublisher = await OV.current.initPublisherAsync(undefined, {
        videoSource: 'screen',
        publishAudio: true, 
        publishVideo: true,
        mirror: false,
      });
  
      
      if (publisher) {
        session.unpublish(publisher);
      }
  
      
      session.publish(screenPublisher);
      setScreenPublisher(screenPublisher);
      setSt(screenPublisher)
      
      setMainStreamManager(screenPublisher);
      setPublisher(screenPublisher);
      console.log(mainStreamManager)

    //   screenPublisher.on('streamDestroyed', (event) => {
    //     // Prevent the default behavior of unsubscribing the stream
    //     event.preventDefault();
    //     console.log('Screen sharing stopped');
    //     // Here you can handle the screen sharing stop event
    // });
    } catch (error) {
      console.error('Error sharing screen:', error);
    }
  };
  



  async function  shareScreen() {

    if (!OV.current) {
      console.error('OpenVidu instance is not initialized');
      return;
    }
    const screenSharePublisher = await OV.current.initPublisherAsync(undefined, {
        videoSource: "screen", 
        publishAudio: true, 
        publishVideo: true,
    });


    if (publisher) {
      session.unpublish(publisher);
    }

    
    session.publish(screenSharePublisher);
    setScreenPublisher(screenSharePublisher);
    setSt(screenSharePublisher)
    
    setMainStreamManager(screenSharePublisher);
    setPublisher(screenSharePublisher);
    setAfterShare(false)

    const videoTrack = screenSharePublisher.stream.getMediaStream().getVideoTracks()[0];
    if (videoTrack) {
        videoTrack.onended = async () => {
            console.log('Screen sharing has been stopped by the user.');
           

          //    await OV.current.initPublisherAsync(undefined, {
          //     audioSource: undefined, 
          //     videoSource: undefined, 
          //     publishAudio: true,
          //     publishVideo: true,
          // });

          setAfterShare(true)
          setSt(null)
          setScreenPublisher(null)
          setStreamKey(Date.now());

          //   if (publisher && !session.capabilities.publish) {
          //     session.publish(publisher);
          //     setMainStreamManager(publisher);
          // }
        
        };
    }
}



  const createSession = async (sessionId) => {
    const response = await axios.post(APPLICATION_SERVER_URL + 'api/sessions', { customSessionId: sessionId }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data; // The sessionId
  };

  const createToken = async (sessionId) => {
    const response = await axios.post(`${APPLICATION_SERVER_URL}api/sessions/${sessionId}/connections`, {}, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data; // The token
  };




  const cameraStreams = subscribers.filter(sub => sub.stream.videoType === 'CAMERA');

  const far = () => {
    console.log(mainStreamManager)
    console.log(mainStreamManager.properties.videoSource)

    if(st !== null){
      const videoTrack = st.stream.getMediaStream().getVideoTracks()[0];
      if (videoTrack) {
          videoTrack.onended = () => {
              console.log('Screen sharing has been stopped by the user.');
              // Perform any necessary cleanup or UI updates here
  
              // Optionally, you can unpublish the stream if needed
              session.unpublish(st);
              setStreamKey(Date.now());
             // setMainStreamManager('undefined');
              setSt(null)
          };
      }
    }
  }

  function showParticipants(){
    console.log('Nums')
    setShowParticipantsList(!showParticipantsList)
  }

  function chk(){
    console.log(subscribers)
  }
  

  return (
    <div className="container">
      {!session && !myUserName && !mySessionId ? (
        <div id="join">
          <div id="join-dialog" className="jumbotron vertical-center">
            <h1>No Session yet</h1>
            
          </div>
        </div>
      ) : null}
       {/* {session && myUserName && mySessionId ?  props( */}
      {session && myUserName && mySessionId ?  (
        <div id="session" style={{position:"absolute",width:"95%",height:"100%"}}> 

         {/* <h1 id="session-title">{mySessionId}</h1> */}

          {/* <h1 id="session-title">{myUserName}</h1> */}
          <div id="session-header" style={{display:"none"}}>
            
            <div style={{display:"none"}}>
            <button
              className="btn btn-large btn-danger"
              onClick={leaveSession}
            >
              Log out
            </button>
          </div>
  
          <button
            className={`btn btn-${audioEnabled ? 'success' : 'danger'}`}
            onClick={toggleAudio}
          >
            {audioEnabled ? 'Mute' : 'Unmute'}
          </button>

          <button className="btn btn-warning" onClick={shareScreen}>Share Screen</button>
          <button className="btn btn-info" onClick={chk}>Check</button>

  
          {/* Additional buttons */}

          <button className={`btn ${videoEnabled ? 'btn-danger' : 'btn-success'}`} onClick={toggleVideo}>
           {videoEnabled ? 'Disable Video' : 'Enable Video'}
         </button>
         </div>







  
    <div id="video-container" className="grid-container"  style={{visibility:showParticipantsList?'visible':'hidden'}} >
              {publisher && st === null &&(
                <div
                  className="stream-container"
                  onClick={() => handleMainVideoStream(publisher)}
                >
                  <UserVideoComponent streamManager={publisher} key={streamKey} />
                  {/* {streamManager !== undefined && */}

                  {/* <video autoPlay={true} ref={videoRef} /> */}
                  
                  {/* } */} 
                  <button 
                      className="mute-button" 
                      onClick={(e) => {  }}
                    >
                     
                     {audioEnabled && publisher?.stream.audioActive ? (
                      <FontAwesomeIcon icon={faMicrophone} />
                    ) : (
                      <FontAwesomeIcon icon={faMicrophoneSlash} />
                    )}
                </button>
                </div>
              )}


          {screenPublisherCt && st !== null && (
              <div className="stream-container">
                <span>Test</span>
                <ShareScreenBox streamManager={screenPublisherCt} key={Date.now()} />
              </div>
            )}




              {/* {subscribers.length > 0 && ( */}
              {subscribers.length > 0 && subscribers.map((sub, i) => (
                <div
                  key={sub.stream.connection.connectionId}
                  className="stream-container"
                  onClick={() => handleMainVideoStream(sub)}
                >
                  <UserVideoComponent streamManager={sub} /> 
                  <button 
                      className="mute-button" 
                      onClick={(e) => {  }}
                    >
                      {sub?.stream.audioActive ? <FontAwesomeIcon icon={faMicrophone} /> : <FontAwesomeIcon icon={faMicrophoneSlash} />}
                      {/* {sub[0].isAudioMuted ? 'Unmute' : 'Mute'} */}
                </button>
                
                </div>
              ))}
              {/* )} */}
   </div>

    

    {/* <div style={{justifyContent:"center",top: "110px", position:"relative",marginLeft: "", width: "100%",display: "flex"}} > */}
    {/* <div style={{width: "70%",bottom: "32px", position:"absolute",marginLeft: "100px"}} > */}
    <div style={{width: "900px",bottom: "16px", position:"absolute",left: "50%",transform: "translate(-48%, 5%)"}} >
         {/* 14% margn -  90 width */}
        <div className='toolbar2' style={{display:""}}>

        <button onClick={toggleVideo} title="Enable/Disable Video" className='ops'>
              <FontAwesomeIcon icon={videoEnabled ? faVideo : faVideoSlash} />
            </button>
        <button onClick={toggleAudio} title="Mute/Unmute Audio" className='ops' >
              <FontAwesomeIcon icon={audioEnabled ? faMicrophone : faMicrophoneSlash} />
            </button>
           
            {/* <button onClick={showParticipants} title="Participants">
              <FontAwesomeIcon icon={faUsers} />
            </button> */}


           <button onClick={showParticipants} className='ops' style={{background:"unset"}} title="Participants">
              <FontAwesomeIcon icon={faUsers} />
            </button> 



            <button onClick={leaveSession} title="End call" className='redbutton'>
              {/* <FontAwesomeIcon icon={faPhoneSlash} /> */}
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" />
                <path fillRule="evenodd" clipRule="evenodd" d="M25.7785 14.3781C26.2087 14.8083 26.4503 15.3917 26.4503 16C26.4503 16.6083 26.2087 17.1917 25.7785 17.6219L25.0368 18.3636C24.5718 18.8286 23.8496 18.9172 23.2857 18.5793L20.2971 16.7855C20.0552 16.6404 19.8614 16.4271 19.7401 16.1723C19.6189 15.9176 19.5755 15.6327 19.6154 15.3534L19.79 14.13C19.8084 14.0024 19.744 13.9067 19.6678 13.8716C18.5329 13.3469 17.2975 13.0752 16.0472 13.0752C14.7969 13.0752 13.5616 13.3469 12.4267 13.8716C12.3504 13.9067 12.2866 14.0018 12.3045 14.13L12.4791 15.3534C12.519 15.6327 12.4756 15.9176 12.3543 16.1723C12.2331 16.4271 12.0393 16.6404 11.7974 16.7855L8.8088 18.5793C8.24492 18.9172 7.52265 18.8286 7.05717 18.3631L6.31597 17.6219C5.88582 17.1917 5.64417 16.6083 5.64416 16C5.64417 15.3917 5.88582 14.8083 6.31597 14.3781L7.53238 13.1617C12.2347 8.45935 19.8597 8.45935 24.5621 13.1617L25.7785 14.3781Z" fill="white"/>
              </svg>
            </button>
        </div>






         <div className="toolbar" style={{display:""}}>
        
          

            <div  style={{width:"57%"}}>
            <div className="d-flex align-items-center" style={{ border:"2px solid #239198",background: '#222',marginLeft:"11px", color: 'white', width:"100%",fontSize:"16px", padding: '7px', borderRadius: '50px' }}>
              <span className="mr-2" style={{ color: '#ffffff',paddingLeft:"5px",textWrap:"nowrap",width:"70px"}}>Public call</span>
              <div className="timer">{formatTimeElapsed(timeElapsed)}</div>
            </div>
            

            </div>

            <Dropdown style={{marginLeft:"21px"}} drop="up">
              <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{background:"#222"}}>
                {/* 17 users are on the call */}
                {subscribers.length + 1} users are on the call
              </Dropdown.Toggle>
              {publisher &&
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">{JSON.parse(publisher.stream.connection.data).clientData}</Dropdown.Item>
              
              {subscribers.length > 0 && subscribers.map((sub, i) => (
                <Dropdown.Item href="#/action-2">
                  {JSON.parse(sub.stream.connection.data).clientData}
                </Dropdown.Item>
                ))}
                {/* Add more Dropdown.Item components for additional users */}
              </Dropdown.Menu>
           }
            </Dropdown>



          </div>

    </div>

        
         


       




        </div>
      ) : null}
    </div>
  );
  
}

export default OpenViduApp;





{/* <div id="video-container" className="grid-container">
  {subscribers.map((sub, i) => (
    <div
      key={sub.stream.connection.connectionId} // Unique key for each subscriber
      className="stream-container"
      onClick={() => handleMainVideoStream(sub)}
    >
      <UserVideoComponent streamManager={sub} />
      <button 
        className="mute-button" 
        onClick={(e) => { e.stopPropagation(); toggleMute(sub); }}
      >
        {sub.isAudioMuted ? 'Unmute' : 'Mute'}
      </button>
    </div>
  ))}
</div> */}