
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import React, { useEffect, useState,useCallback } from 'react';
import {UNITY_URLS} from './utils/config';
import "react-tooltip/dist/react-tooltip.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import FileDropForm from "./components/modal"
import { useLocation } from 'react-router-dom';
import WebViewer from './components/WebViewer'
import OpenViduApp from './components/openvidu/openVideo11';


const App = () => {
     
  const [trigger, setTriggerLeave] = useState(false);
  const [conferenceUID, setConferenceUID] = useState()
  const [u, setusern] = useState()
  const [m, setmeetingn] = useState()
  const [r, setrole] = useState('')
  const [isOpenbtn, setIsopen] = useState(false)
  const[isCall,setCall] = useState()
  const [progress, setProgress] = useState(0);
  const [loadConfig, setLoadConfig] = useState(false);
  //static
  //const { unityProvider, sendMessage, addEventListener, removeEventListener, loadingProgression,isLoaded } = useUnityContext(UNITY_URLS);
  const [webUrl, setWebUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [UserValue, setValue] = useState('');
  const [metaverse_id, setMeta] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [record, setRecord] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [error, setError] = useState(null);
  const[iframeData,setiframeData] = useState()
  const[configData,setConfigData] = useState()
  const [Init, setInit] = useState(false)
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const location = useLocation();
  const [allMessages, setAllMessages] = useState({});

  //Dynamic
  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    loadingProgression,
    isLoaded
  } = useUnityContext({
    loaderUrl: localStorage.getItem('loaderUrl'),
    frameworkUrl: localStorage.getItem('frameworkUrl'),
    dataUrl: localStorage.getItem('dataUrl'),
    codeUrl: localStorage.getItem('codeUrl'),
    streamingAssetsUrl: localStorage.getItem('streamingAssetsUrl')
    
  });


  const handleFileAdded = (newFile) => {
    setFile(newFile);
    console.log(newFile)
  };

  
const openModal = (url) => {
  //setWebUrl(url)
  setWebUrl(url);
  setModalIsOpen(true);
};

const closeModal = () => {
  setModalIsOpen(false);
  setWebUrl('');
};


  const loadingPercentage = Math.round(loadingProgression * 100);






  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('user_id');
    const metaId = searchParams.get('metaverse_id');
    console.log("iFrameData:"+ JSON.stringify(iframeData))
   if(iframeData){
      fetch('https://api.builder.holofair.io/api/config/client?metaverse_id=3')
        .then(response => response.json())
        .then(fetchedData => {
          console.log(fetchedData);
          fetchedData["user_id"] = iframeData?.user_id || userId;
          fetchedData["metaverse_id"] = iframeData?.metaverse_id || metaId || "3" ;
          setConfigData(fetchedData);
        })
        .catch(error => console.error('Error fetching the JSON file:', error));
  }
  
  if(metaId){
    fetch('https://api.builder.holofair.io/api/config/client?metaverse_id=3')
    .then(response => response.json())
    .then(fetchedData => {
      // console.log(fetchedData);
      fetchedData["user_id"] = userId || 1;
      fetchedData["metaverse_id"] = metaId || 3;
      setConfigData(fetchedData);
     // console.log(fetchedData);
    })
    .catch(error => console.error('Error fetching the JSON file:', error));

  }
    
  }, [iframeData, iframeData?.metaverse_id, iframeData?.user_id, location.search, metaverse_id]);



  // useEffect(()=> {
  
        
  //       if(isLoaded && configData && Init === false){

  //         const searchParams = new URLSearchParams(location.search);
  //         const userId = searchParams.get('user_id');
  //         const metaId = searchParams.get('metaverse_id');
          
  //         const configJSON = {
  //           "metaverse_id": metaId || "3",
  //           "user_id": configData?.user_id || userId,
  //           "APIAddress":"https://api.builder.holofair.io/api/addressables/{metaverse_id}/{platform}",
  //           "Host":"",
  //           "Port":"",
  //           "MaxClients":"256",
  //           "UserProfileAPIGETAddress":"https://majidalfuttaim.enterprise.holofair.io/api/user_profile?user_id=",
  //           "AnalyticsStartedAPI":"https://api.builder.holofair.io/api/interactions/add/started?metaverse_id={metaverse_id}",
  //           "AnalyticsEndedAPI":"https://api.builder.holofair.io/api/interactions/update/ended?metaverse_id={metaverse_id}",
  //           "AnalyticsImmediateAPI":"https://api.builder.holofair.io/api/interactions/add/immediate?metaverse_id={metaverse_id}",
  //           "useHathora":"True",
  //           //"data":iframeData || {}
  //         };
  //         //console.log(configData)
  //         sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(configData));
  //         setiframeData(null)

  //               setTimeout(() => {
  //                 if(iframeData === null)
  //                   setInit(true)
  //               }, 1000);
  //       }

  //       if(Init === true && iframeData){
  //           sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(iframeData));
  //           setiframeData(null);
          
  //       }
       
  

  // },[UserValue, iframeData, isCall, isLoaded, loadConfig, location.search, m, progress, record, removeEventListener, sendMessage, u, configData, metaverse_id, Init])
  
     


  useEffect(() => {
    if (isLoaded && configData && !Init) {
      //console.log("######A")
      if(Init === false){
      sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(configData));
      }
     
  
      //console.log("######B")
      setTimeout(() => {
       
        setiframeData(null);  
          setInit(true);
        
      }, 1000);
    }
  }, [isLoaded, configData, Init, location.search, sendMessage, iframeData]);

  
  
  useEffect(() => {
    if (Init === true && iframeData) {
     // console.log("######2")
      sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(iframeData));
      setiframeData(null); 
    }
  }, [Init, iframeData, sendMessage]);



  useEffect(() =>{
    if(!iframeData && isLoaded && configData && Init === false){
       sendMessage("JsonSubjectsCollectionController", "ImportJsonString", JSON.stringify(configData));
     }
     
  })















  useEffect(()=>{
    localStorage.setItem('ov','');
   
  })
  const unityCtrlOff = () => {
    console.log('clicked off');
    sendMessage("App","Lock",)
  };

  const unityCtrlOn = () => {
    console.log('clicked on');
    sendMessage("App","Unlock",)
  };


const getValue = useCallback((value)=>{
  console.log("Received from Unity: ",value)
  openModal(value);
},[]);


const handleMessageData = useCallback((event) => {
  const message = event.data;
  console.log(message);

 
  if (message.type === 'holofair') {
      console.log('Received holofair data:', message.data);

      
      setAllMessages((prevMessages) => ({
          ...prevMessages,
          holofair: message.data
      }));
  }

 
  if (message.type === 'beforeLoad') {
      console.log('Received beforeLoad data:', message.data);

      
      setAllMessages((prevMessages) => ({
          ...prevMessages,
          beforeLoad: message.data
      }));
  }
}, []);

useEffect(() => {

  console.log("Updated allMessages:", allMessages);

  setiframeData(allMessages);
}, [allMessages]);


useEffect(() => {
  console.log("Updated allMessages:", allMessages);
  console.log(allMessages.holofair)

  
  setiframeData(allMessages.holofair);
}, [allMessages]); 


useEffect(()=> {

  window.addEventListener('message', handleMessageData);
  addEventListener("Display",getValue);
  return () => {
     window.addEventListener('message', handleMessageData);
     removeEventListener("Display",getValue);
  }


},[addEventListener, getValue, handleMessageData, removeEventListener])


  return (
    <>
  
      <React.StrictMode>
      {/* <AppBase> */}
      <div  onClick={unityCtrlOff}>
      {/* <FileDropForm onFileSelect={handleFileSelect}  myEdit={addEventListener} myCall={sendMessage} removeEvent={removeEventListener} /> */}
      </div>
      {/* <ChatApp  myChat={sendMessage} myEvent={addEventListener} myCleanUp={removeEventListener} /> */}
        {isCall === false  && progress < 100 &&
          <div className='spinnerCall'>
              <div>
                <progress style={{width:"350px",height:"5px"}} value={progress} max="100"></progress>
                <p style={{textAlign:"center", background: "#9A2F23",width: "45px",margin: "auto",color: "#fff", borderRadius: "50px"}}
                >{progress}%</p>
              </div>
          </div>
           } 
         
      {/* {isOpenbtn && conferenceUID &&
        <Content  update={funcOpen} usern={u} mname={m} id={conferenceUID} role={r}  videoTag={sendMessage}/> 
      }  */}

      {/* {isOpenbtn && u && m &&
        <OpenViduApp 
         update={funcOpen}
         triggerLeave={handleLeaveSession}
         username={u} sessionname={m} /> 
      }  */}
      
      <div  onClick={unityCtrlOff}>
       <FileDropForm
        // isOpen={isModalOpen}
        // onClose={() => setIsModalOpen(false)}
        onFileAdded={handleFileAdded}
        file={file} 
        myEdit={addEventListener} 
        myCall={sendMessage}
        removeEvent={removeEventListener}
      />
      </div>
   
 
   {isLoaded === false && (
    <>
     <div className="progress-overlay">
      </div> 
       <div className="progress-bar">
       <h2>{loadingPercentage}%</h2>
       <progress style={{margin:"0 auto",width:"80%",height:"50%",border:"1px solid #8A1538"}} id="file2" value={loadingPercentage} max="100">({loadingPercentage}%)</progress>
       </div>
       </>
     )} 

    <WebViewer isOpen={modalIsOpen} onClose={closeModal} url={webUrl} />
     <div onClick={unityCtrlOn} >
     <Unity style={{ width: "100%",height:"100vh" , visibility: isLoaded ? "visible" : "hidden"}} unityProvider={unityProvider}  devicePixelRatio={devicePixelRatio} />
     </div>

      {/* </AppBase> */}
      </React.StrictMode>
    </>
  )
}

export default App;