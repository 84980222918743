// import React, { Component } from 'react';

// export default class OpenViduVideoComponent extends Component {

//     constructor(props) {
//         super(props);
//         this.videoRef = React.createRef();
//     }

//     componentDidUpdate(props) {
//         if (props && !!this.videoRef) {
//             this.props.streamManager.addVideoElement(this.videoRef.current);
//         }
//     }

//     componentDidMount() {
//         if (this.props && !!this.videoRef) {
//             this.props.streamManager.addVideoElement(this.videoRef.current);
//         }
//     }

//     render() {
//         return <video autoPlay={true} ref={this.videoRef} />;
//     }

// }


import React, { useEffect, useRef,useState } from 'react';

const OpenViduVideoComponent = ({ streamManager, key2 }) => {
    const videoRef = useRef(null);
    const [play, setPlay] = useState(false);
    const [intervalId, setIntervalId] = useState(null);

  

    useEffect(() => {
        
      
        if(videoRef.current && streamManager && streamManager.stream) {
            videoRef.current.srcObject = streamManager.stream.getMediaStream();
          }
        
    }, [streamManager, play, intervalId, key2]); 

   

    useEffect(() => {
       
      }, [intervalId, play]); // Dependency on 'play'


 

    // return (
    // <div>
    //     <button onClick={ck}>Check Status</button>
    //  <video autoPlay={true} ref={videoRef} />;
    // </div>
    // )
    return <video autoPlay={true} ref={videoRef} />;
    
};

export default OpenViduVideoComponent;

