import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';

const FileDropForm = (props) => {
  const [show, setShow] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [modalHeaderText, setModalHeaderText] = useState({ title: '', subTitle: '' });
  const [keyWeb, setKeyWeb] = useState(Date.now());

  const [urlInput, setUrlInput] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [waitUpload, setWaitUpload] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [fileTypeUpload, setFileTypeUpload] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errors, setErrors] = useState('');
  const [file, setFile] = useState(null);
  const [formKey, setFormKey] = useState(0);
  const [key, setKey] = useState(null); 
  const fileInputRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState('option0');
  

  const maxbps = 40960000;
  const maxVideoSize = 600 * 1024 * 1024; 


 

  const handleClose = useCallback( () => {
    setShow(false);
    resetForm();
    props.myCall('App', 'Unlock');
  },[props])

  const handleShow = useCallback(() => {
    setShow(true);
    setFormKey(prevKey => prevKey + 1);
    resetForm();
    props.myCall('App', 'lock');
  }, [props]);

  const handleLink = useCallback(() => {
    setShowLink(true);
    props.myCall('App', 'lock');
  }, [props]);

  const handleCloseLink = () => {
    setShowLink(false);
    props.myCall('App', 'Unlock');
  };


  const logResult2 = useCallback((key,value,dt,ft) => {
        
    //console.log(key,value,dt,ft)
    console.log(key)
    console.log(value)
    console.log(dt)
    console.log(ft)
    setKey(key)

    if(ft === 'VIDEO'){
      setFileTypeUpload(ft)
      handleShow();
      console.log(key)
    }
    if(ft === 'IMAGE'){
      setFileTypeUpload(ft)
      handleShow();
      console.log(key)
    }
    if(ft === 'FILE'){
      // setFileTypeUpload(ft)
      setFileTypeUpload('PDF')
      handleShow();
      console.log(key)

    }
    if(ft ==='LINK'){
      setFileTypeUpload("LINK")
      handleLink();
      console.log(key)

    }
  },[handleLink, handleShow]);

  const resetForm = () => {
    setFile(null);
    setUploadProgress(0);
    setInputValue('');
    setSuggestions([]);
    setErrors('');
    setUploadedFile(null);
    setSelectedOption("option0");
  };

  const handleUploadProgress = (progressEvent) => {
    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadProgress(progress);
  };

  const onLoadfile = useCallback((file) => {
    console.log(file)
    if (file) {
      const file2 = file;
      if (fileTypeUpload === 'IMAGE') {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (file2 !== '' && allowedTypes.includes(file2.type)) {
          const reader = new FileReader();
          const img = new Image();
          reader.onload = (e) => {
            img.src = e.target.result;
            img.onload = () => {
              if (img.width <= 2048 && img.height <= 2048) {
                setErrors('');
                setFile(file2);
                setUploadedFile(file2);
                props.onFileAdded(file2);
              } else {
                setErrors('Image dimensions should not exceed 2048x2048 pixels.');
                setFile(null);
              }
            };
          };
          reader.readAsDataURL(file2);
        } else {
          setErrors('Invalid file type');
          setFile(null);
        }
      }
  
      if (fileTypeUpload === 'VIDEO') {
        if (file2 !== '' && file2.type === 'video/mp4' && file2.size <= maxVideoSize) {
          const video = document.createElement('video');
          video.src = URL.createObjectURL(file2);
          video.addEventListener('loadedmetadata', () => {
            const bitsPerByte = 8;
            const bps = (file2.size * bitsPerByte) / video.duration;
            
            if (bps > maxbps) {
              setErrors('Data rate exceeds the limits');
              setFile(null);
            } else {
              setFile(file2);
              setErrors('');
            }
          });
          setFile(file2);
        } else {
          setErrors('Invalid file type or size exceeds 600 MB');
          setFile(null);
        }
      }
  
      if (fileTypeUpload === 'FILE') {
        if (file2 !== '' && file2.type === 'application/pdf') {
          setErrors('');
          setFile(file2);
          setUploadedFile(file2);
          props.onFileAdded(file2);
        } else {
          setErrors('Invalid file type');
          setFile(null);
        }
      }
    }
  }, [fileTypeUpload, maxVideoSize, maxbps, props]);

  const getAccept = () => {
    switch (fileTypeUpload) {
      case 'IMAGE':
        return {
          'image/jpeg': ['.jpeg', '.jpg'],
          'image/png': ['.png']
        };
      case 'VIDEO':
        return {
          'video/mp4': ['.mp4']
        };
      case 'FILE':
        return {
          'application/pdf': ['.pdf']
        };
      default:
        return {};
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    setUploadedFile(file);
    props.onFileAdded(acceptedFiles[0]);
    onLoadfile(file);
  }, [onLoadfile, props]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: getAccept(),
  });

  const handleInputChange = (event) => {
    setSelectedValue('');
    props.myCall("App", "lock");
    setInputValue(event.target.value);
    searchFile(event.target.value);
  };

  const searchFile = async (val) => {
    setSuggestions([]);
    const response = await fetch(`https://api.gdrfa.holofair.io/api/files/all?type=${fileTypeUpload}&keyword=${val}`);
    const data = await response.json();
    if (data.length > 0) {
      setSuggestions(data);
    }
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setInputValue(value);
    setSuggestions([]);
  };

  useEffect(() => {
    if (uploadProgress === 100) {
      setTimeout(() => {
        handleClose();
        setWaitUpload(false);
      }, 2000);
    }
  }, [handleClose, uploadProgress]);

  useEffect(() =>{

    props.myEdit('edit', logResult2);
    return () => {
      props.removeEvent('edit', logResult2);
     
      
        };

  },[logResult2, props])

  const SendReq = async () => {
    setWaitUpload(true);
    const formData = new FormData();
    formData.append('file', uploadedFile);
    var obj = {}
   

    try {
      if (uploadedFile) {
        // const response = await axios.post('http://localhost:5005/files/upload', formData, {
         // await axios.post('https://majidalfuttaim.enterprise.holofair.io/api/files/upload', formData, {
         //api.gdrfa.holofair.io/files
         await axios.post('https://api.gdrfa.holofair.io/api/files/upload',formData,{
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: handleUploadProgress,
          timeout: 5 * 60 * 1000 
        }).then(response => {
          console.log(response)
          setTimeout(() => {
            obj[key] = "https://api.gdrfa.holofair.io" + response.data.url;
            console.log(JSON.stringify(obj));
            props.myCall("App","Process",JSON.stringify(obj))

          },1500)
         
        
        if (response.status === 200) {
          setUploadProgress(100); 
         
          setTimeout(() => {
            //handleClose();
            props.myCall("App", "Unlock");
            setWaitUpload(false);
          }, 3000);
        }
        else if (response.status === 413){
          setErrors('File size exceeds the maximum limit');
        }
        
        else{
          setUploadProgress(0);
          setErrors("Something went wrong")
          setWaitUpload(false);
        }



        
        });
       
      } else {
        props.myCall("App", "Unlock");
        setWaitUpload(false);
        
      }
    } catch (error) {
      setErrors(error.message);
      setWaitUpload(false);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleUrlChange = (e) => {
    const inputUrl = e.target.value;
    setUrlInput(inputUrl);
    setIsValid(validateURL(inputUrl));
  };

  const validateURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i');
    return !!pattern.test(str);
  };

  return (
    <div>
      <Modal show={showLink} onHide={handleCloseLink} size="lg" aria-labelledby="contained-modal-title-vcenter">
        <div style={{ background: "rgb(227 227 227)" }}>
          <button className='closeModalBtn x-icon' onClick={handleCloseLink}></button>
          <Modal.Title style={{ color: "#000", paddingLeft: "20px",paddingTop:"15px" }}>Edit {fileTypeUpload}</Modal.Title><p></p>
         {/* <h5 style={{ color: "#fff", paddingLeft: "15px", fontSize: "9pt" }}>{key}</h5> */}
        </div>
        <Modal.Body>
          <Form.Group>
            <Form.Label>URL <span style={{ color: "red" }}>*</span> </Form.Label>
            <Form.Control
              type="url"
              placeholder="Enter URL"
              onChange={handleUrlChange}
              style={{ border: isValid ? '' : '1px solid red', color: "#000" }}
              as="input"
              rows={5}
            />
            {!isValid && <Form.Text className="text-danger">The URL entered is not valid</Form.Text>}
          </Form.Group>
        </Modal.Body>
      
        <Modal.Footer style={{ margin: "", paddingRight: "20px" }}>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          {!waitUpload ? (
            <Button variant="danger" onClick={SendReq} disabled={!!errors} >Update</Button>
          ) : (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter">
        <div style={{ background: "rgb(227 227 227)" }}>
          <button className='closeModalBtn x-icon' onClick={handleClose}></button>
          <Modal.Title style={{ color: "#000", paddingLeft: "20px",paddingTop:"15px" }}>Edit {fileTypeUpload}</Modal.Title><p></p>
          {/* <h5 style={{ color: "#fff", paddingLeft: "15px", fontSize: "9pt" }}>{key}</h5> */}
        </div>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <div className="row">
            <div className="column">
              <h6>Choose how to upload a file</h6>
              <select value={selectedOption} className="input-field" onChange={handleOptionChange}>
                <option value="option0">Select an option</option>
                <option value="option1">Upload from PC</option>
                <option value="option3">Upload from an existing URL</option>
              </select>

              {selectedOption === 'option1' && (
                <div key={keyWeb}>
                  <br />
                  <span style={{ color: '#A70003', fontSize: '11pt' }}> * {fileTypeUpload === 'IMAGE' ? ' JPEG, PNG' : fileTypeUpload === 'VIDEO' ? ' MP4' : fileTypeUpload === "FILE" ? ' PDF' : ''} file required </span>
                  <div {...getRootProps()} style={getStyle(isDragActive)}>
                    <input {...getInputProps()} />
                    {uploadedFile ? (
                      <p>{uploadedFile.name}</p>
                    ) : (
                      <p>{isDragActive ? 'Drop the file here ...' : 'Click or Drag & Drop a file here'}</p>
                    )}
                  </div>
                  {/* {uploadedFile?.name && <p>Uploaded file: {uploadedFile.name}</p>} */}
                  <br></br>

                  {errors && <p style={{ color: '#A70003',textAlign:"center" }}>{errors}</p>}
                  {waitUpload && !errors && (
                    <div style={{ margin: "auto", display: "table" }}>
                      <progress style={{ background: "", color: "#001630 !important",border:"1px dotted gray" }} value={uploadProgress} max="100" />
                      <span style={{position:"relative",bottom:"2px",left:"2px"}}>{uploadProgress}%</span>
                    </div>
                   )} 
                  <div>
                    {uploadProgress === 100 && <p style={{ textAlign:"center",borderRadius: "50%" }}>File has been uploaded successfully</p>}
                  </div>
                </div>
              )}

              {selectedOption === 'option3' && (
                <div>
                  <h6> <span style={{ color: '#A70003', fontSize: '11pt' }}> * {fileTypeUpload === 'IMAGE' ? ' JPEG, PNG' : fileTypeUpload === 'VIDEO' ? ' MP4' : fileTypeUpload === "FILE" ? ' PDF' : ''} file required </span> </h6>
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Type to search..."
                    className="input-field"
                  />
                  {!selectedValue && (
                    <ul className="suggestions-list">
                      {suggestions.map((item, index) => (
                        <li key={index} onClick={() => handleSelect(item.link)}>
                          {item.link}
                        </li>
                      ))}
                    </ul>
                  )}
                  {selectedValue && (
                    <p className="selected-value" style={{
                      width: "300px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                      {selectedOption === 'option3' && (
                        <div>
                          {fileTypeUpload === 'IMAGE' ? (
                            <img src={inputValue} alt='' style={{ width: "300px", borderRadius: "10px" }} />
                          ) : fileTypeUpload === 'VIDEO' ? (
                            <video controls width="300" height="180">
                              <source src={inputValue} type="video/mp4" />
                            </video>
                          ) : null}
                        </div>
                      )}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ margin: "", paddingRight: "20px" }}>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          {!waitUpload ? (
            <Button variant="danger" onClick={SendReq} disabled={!!errors} >Upload</Button>
          ) : (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FileDropForm;

const getStyle = (isDragActive) => ({
  border: '2px dashed #D4D4D4',
  borderRadius: '5px',
  padding: '20px',
  textAlign: 'center',
  color: isDragActive ? '#00b300' : '#001D3C',
  cursor: 'pointer',
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

